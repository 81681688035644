import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import Text from 'common/src/components/Text';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'common/src/components/Image';
import BannerWrapper, {
  BannerContent,
  Subscribe,
  BannerImage,
} from './banner.style';

import { ButtonWrapper } from '../../Portfolio/portfolio.style';
import Link from 'common/src/components/Link';
import bannerImg from 'common/src/assets/image/appClassic/bannerimage.png';

const Banner = ({ buttonStyle }) => {
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading as="h1" content="Software Engineer / Cloud Architect" />
          </Fade>
          <Fade up delay={200}>
            <Text
              className="banner-caption"
              content="I'm a Software Engineer / Cloud Architect specializing in building outstanding web applications, mobile apps and cloud architectures. Currently I am very passionate about developer tools, LLMs and generative AI"
            />
          </Fade>
          <Fade up delay={300}>
            <Subscribe>
              <ButtonWrapper>
                <Link href="#contact_section">
                  <Button
                    title="Get In Touch"
                    className="portfolio_button"
                    {...buttonStyle}
                  />
                </Link>
              </ButtonWrapper>
            </Subscribe>
          </Fade>
          <Fade up delay={400}></Fade>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

Banner.propTypes = {
  buttonStyle: PropTypes.object,
};

Banner.defaultProps = {
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    pl: '23px',
    pr: '23px',
  },
};

export default Banner;
