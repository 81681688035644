import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import { plus } from 'react-icons-kit/feather/plus';

import ProcessItem from './process.style';

const ProcessSection = ({
  sectionWrapper,
  processRow,
  processCol,
  processImageStyle,
  processTitleStyle,
  processDescriptionStyle,
  learningRow,
  learningContentArea,
  learningListArea,
  learningTitle,
  learningSubTitle,
  learningDescription,
  buttonWrapper,
  buttonLabelStyle,
  buttonStyle,
  learningList,
  listItem,
  listText,
  listTitle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      portfolioJson {
        PROCESS_STEPS {
          title
          description
          image {
            publicURL
          }
        }
        SERVICE_LIST {
          title
          listItems {
            content
          }
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper} as="section" id="speciality_section">
      <Container noGutter mobileGutter width="1200px">
        <Fade bottom>
          <Box {...processRow}>
            {Data.portfolioJson.PROCESS_STEPS.map((item, index) => (
              <Box
                {...processCol}
                key={`process-item-${index}`}
                className="process_item_col"
              >
                <ProcessItem className="process_item">
                  <Image
                    src={item.image.publicURL}
                    alt={`process-image-${index + 1}`}
                    {...processImageStyle}
                  />
                  <Heading
                    as="h3"
                    content={item.title}
                    {...processTitleStyle}
                  />
                  <Text
                    content={item.description}
                    {...processDescriptionStyle}
                  />
                </ProcessItem>
              </Box>
            ))}
          </Box>
        </Fade>
        <Fade left>
          <Box {...learningRow} id="about_section">
            <Box {...learningContentArea}>
              <Heading content="About Me" {...learningTitle} />
              <Text
                {...learningDescription}
                content="I'm a highly focused Software Engineer with over 7 years of experience
              building scalable, fast and highly available applications."
              />
              <Text
                {...learningDescription}
                content="I have had the opportunity to work for several interesting companies and businesses of different sizes around the world, ranging from seed stage startups to public companies to help develop complex software applications and solutions"
              />
              <p
                {...learningDescription}
                style={{ color: '#43414e', lineHeight: '1.5' }}
              >
                Currently I am a Software Engineer at{' '}
                <a
                  href="https://www.vial.com/"
                  target="_blank"
                  style={{ color: '#40ccb7' }}
                >
                  {' '}
                  Vial,{' '}
                </a>{' '}
                working with an awesome Engineering team and building an end to
                end technology platform for carrying out more efficient clinical
                trials.
                <br />
                <br />I have previously worked as a Senior full stack developer
                for{' '}
                <a
                  href="https://www.evidencity.com/"
                  target="_blank"
                  style={{ color: '#40ccb7' }}
                >
                  {' '}
                  Evidencity{' '}
                </a>{' '}
                which is a global due diligence and compliance company in the
                US. Where I helped build the V3 of the web application which
                handles many of the day to day activities with agents all over
                the world."
              </p>
              <p
                {...learningDescription}
                style={{ color: '#43414e', lineHeight: '1.5' }}
              >
                I also worked as a Senior Software Engineer Contractor for{' '}
                <a
                  href="https://www.razu.io/"
                  target="_blank"
                  style={{ color: '#40ccb7' }}
                >
                  {' '}
                  Razu{' '}
                </a>{' '}
                where I built tools and interfaces to help connect musicians and
                producers. To enable them share data in the cloud and
                collaborate on various projects
              </p>
              <p
                {...learningDescription}
                style={{ color: '#43414e', lineHeight: '1.5' }}
              >
                I have also previously worked as a contractor for companies like{' '}
                <a
                  href="https://www.9spokes.com/"
                  target="_blank"
                  style={{ color: '#40ccb7' }}
                >
                  {' '}
                  9spokes{' '}
                </a>{' '}
                where I built tools to help connect the platform to services
                like quickbooks, sage and zendesk"
              </p>
              <Text
                {...learningDescription}
                content="I also once worked with  Blueridge.Ai. Where I worked on a suite of tools and reports to help them analyse, understand and make decisions using the data available on the platform"
              />
              <p
                {...learningDescription}
                style={{ color: '#43414e', lineHeight: '1.5' }}
              >
                More Details about my work can be found in
                <a
                  href="https://www.sampsonovuoba.me/resume.pdf"
                  target="_blank"
                  style={{ color: '#40ccb7' }}
                >
                  {' '}
                  My resume
                </a>{' '}
              </p>
              {/* <Text
                {...learningDescription}
                content={`I also once worked at a startup, Curacel where I focused on building tools and applications for Insurance companies and hospitals to accept Claims and visualize reports. You can find more details of the various places I have been opportuned to work in my resume.`}
              /> */}

              {/* <Text
              {...learningDescription}
              content="While at Neverbland over the last few years, I've worked on web and product solutions for a range of startups, in a variety of industries."
            /> */}
            </Box>

            <Box {...learningListArea}>
              {Data.portfolioJson.SERVICE_LIST.map((serviceList, index) => (
                <Box {...learningList} key={`serviceList-${index}`}>
                  <Heading content={serviceList.title} {...listTitle} />
                  {serviceList.listItems.map((item, index) => (
                    <Box {...listItem} key={`list-item-${index}`}>
                      <Icon
                        icon={item.icon || plus}
                        size={item.iconSize || 12}
                      />
                      <Text as="span" content={item.content} {...listText} />
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

ProcessSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object,
  learningRow: PropTypes.object,
  learningContentArea: PropTypes.object,
  learningListArea: PropTypes.object,
  learningTitle: PropTypes.object,
  learningSubTitle: PropTypes.object,
  learningDescription: PropTypes.object,
  buttonWrapper: PropTypes.object,
  buttonLabelStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  learningList: PropTypes.object,
  listItem: PropTypes.object,
  listText: PropTypes.object,
  listTitle: PropTypes.object,
};

ProcessSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '140px'],
    pb: ['10px', '40px', '30px', '50px', '50px'],
  },
  secTitleWrapper: {
    mb: ['60px', '105px'],
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'center',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'center',
  },
  processRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['0', '-15px', '-30px', '-70px', '-70px'],
    mr: ['0', '-15px', '-30px', '-70px', '-70px'],
  },
  processCol: {
    width: [1, 1 / 3],
    pl: ['0', '15px', '30px', '70px', '70px'],
    pr: ['0', '15px', '30px', '70px', '70px'],
    mb: '40px',
  },
  processImageStyle: {
    ml: 'auto',
    mr: 'auto',
    mb: '35px',
  },
  processTitleStyle: {
    fontSize: ['20px', '18px', '20px', '20px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    textAlign: 'center',
    mb: ['20px', '20px', '27px', '27px', '27px'],
  },
  processDescriptionStyle: {
    fontSize: ['15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    textAlign: 'center',
    lineHeight: '1.5',
  },
  learningRow: {
    flexBox: true,
    flexWrap: 'wrap',
    mt: ['20px', '30px', '70px', '80px', '110px'],
  },
  learningContentArea: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    pr: ['0px', '0px', '60px', '80px', '160px'],
    mb: ['70px', '70px', '0', '0', '0'],
  },
  learningTitle: {
    fontSize: ['22px', '22px', '24px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['20px', '20px', '15px', '20px', '20px'],
    pr: ['0', '0', '0', '65px', '65px'],
  },
  learningSubTitle: {
    fontSize: ['16px', '16px', '18px', '20px', '20px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '20px',
    pr: ['0', '0', '0', '65px', '65px'],
  },

  learningDescription: {
    fontSize: '16px',
    lineHeight: '1.5',
    mb: '25px',
    boxSizing: 'border-box',
    marginTop: '0',
    marginBottom: '25px',
    fontSize: '16px',
    color: '#43414e',
    lineHeight: '1.5',
  },
  buttonWrapper: {
    flexBox: true,
    alignItems: 'center',
    mt: ['30px', '40px', '40px', '80px', '80px'],
    flexWrap: ['wrap'],
  },
  buttonLabelStyle: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#0ba786',
    mb: ['20px', '20px', '20px', '0', '0'],
    mr: '30px',
    width: ['100%', '100%', '100%', 'auto', 'auto'],
  },
  buttonStyle: {
    type: 'button',
    fontSize: '16px',
    fontWeight: '500',
    color: '#fff',
    pl: '23px',
    pr: '23px',
  },
  learningListArea: {
    width: ['100%', '100%', '50%', '50%', '50%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  learningList: {
    width: ['100%', '33.3333333%', '50%', '50%', '50%'],
    pl: ['0', '0', '35px', '35px', '35x'],
    pr: ['0', '30px', '0', '0', '0'],
    mb: ['40px', '40px', '60px', '80px', '90px'],
  },
  listTitle: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#302b4e',
    mb: '25px',
  },
  listItem: {
    flexBox: true,
    alignItems: 'center',
    color: '#43414e',
    mb: '16px',
  },
  listText: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#43414e',
    mb: '0',
    ml: '5px',
  },
};

export default ProcessSection;
